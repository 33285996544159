/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import {
  getDueDateForInvoice, getUserData, isEnableBed, isEnableLocationModule,
} from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import invoiceStoreModule from '../invoiceStoreModule';

export default function useInvoiceList() {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }
  const { t } = useI18nUtils();
  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const isResident = process.env.VUE_APP_PROVIDER === 'resident';
  const dueDate = getDueDateForInvoice();
  const userData = getUserData();

  const blankItem = {
    id: 0,
    month: moment(new Date()).format("MM-YYYY"),
    name: '',
    apartment: null,
    room: null,
    bed: null,
    contract: null,
    issueDate: isTingTong ? moment(new Date()).startOf('month').subtract(1, 'month').add(27, 'days')
      .toDate() : moment(new Date()).toDate(),
    finalDate: null,
    dueDate,
    note: '',
    items: [],
    subTotal: 0,
    discount: 0,
    promotion: 0,
    prepaid: 0,
    tax: 0,
    total: 0,
    typeObject: { title: t('Hóa đơn hàng tháng'), id: '2', value: '2' },
    type: '2',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const paymentItem = ref({});

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'invoice') && !vm.$can('delete', 'invoice') && !vm.$can('approve', 'invoice') && !vm.$can('pay', 'invoice');
  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '100px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: t('Tên'),
      field: 'name',
    },
    {
      label: t('Khách hàng'),
      field: 'customer',
    },
    {
      label: t('Tiền thuê'),
      field: 'totalLease',
      type: 'number',
      sortable: false,
      hidden: !isResident || (userData && userData.id === 1464),
    },
    {
      label: t('Tiền dịch vụ'),
      field: 'totalOtherFee',
      type: 'number',
      sortable: false,
      hidden: !isResident || (userData && userData.id === 1464),
    },
    {
      label: t('Tổng tiền'),
      field: 'total',
      type: 'number',
    },
    {
      label: t('Đã trả'),
      field: 'paid',
      type: 'number',
    },
    {
      label: t('Nợ'),
      field: 'remain',
      type: 'number',
    },
    {
      label: t('Nợ cộng dồn'),
      field: 'totalDebt',
      type: 'number',
    },
    {
      label: t('Hạn TT'),
      field: 'dueDate',
    },
    {
      label: t('Duyệt'),
      field: 'approve',
    },
    {
      label: t('Người tạo'),
      field: 'creator',
      hidden: !isTingTong,
    },

  ];
  const rows = ref([]);
  const analytics = ref(null);
  // filter
  const location = ref(null);
  const apartment = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const contract = ref(null);
  const status = ref(null);
  const type = ref(null);
  const mixedType = ref(null);
  const invoiceType = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  const month = ref(null);
  const approveStatus = ref(null);
  const paymentStatus = ref(null);
  const dueDateStatus = ref(null);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {

    },
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchInvoices = () => {
    isLoading.value = false;
    store
      .dispatch('invoice/fetchInvoices', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items.map(object => {
          let totalLease = 0;
          let totalOtherFee = 0;
          if (object.items) {
            for (const eachObjectItem of object.items) {
              if (eachObjectItem.feeTypeId === 'lease') {
                totalLease += eachObjectItem.total;
              } else if (eachObjectItem.feeTypeId !== 'deposit') {
                totalOtherFee += eachObjectItem.total;
              }
            }
          }
          return {
            ...object,
            totalLease,
            totalOtherFee,
          };
        });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    isLoading.value = false;
    store
      .dispatch('invoice/fetchAnalytics', serverParams.value)
      .then(response => {
        analytics.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchUserConfiguration = () => {
    store
      .dispatch('setting/fetchUserConfiguration', serverParams.value);
  };

  const fetchData = () => {
    fetchInvoices();
    fetchAnalytics();
    fetchUserConfiguration();
  };

  const deleteInvoices = invoices => {
    store
      .dispatch('invoice/deleteInvoices', {
        ids: invoices.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('invoice/importInvoices', formData)
      .then(() => {
        // fetchData();
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('invoice/exportInvoices', serverParams.value)
      .then(response => {
        if (response.data === true) {
          vm.$router.push({ name: 'jobs' });
        } else {
          window.location.href = response.data;
        }
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const downloadImportTemplate = data => {
    let filter = {
      
    }
    if (data && data.apartment) {
      filter = {...filter,
        apartmentId: data.apartment.id
      }
    }
    if (data && data.month) {
      filter = {...filter,
        month: moment(data.month, "MM-YYYY", true).isValid() ? data.month : moment(data.month).format('MM-YYYY')
      }
    }
    
    store
      .dispatch('invoice/generateImportTemplate', { filter})
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportZipImages = () => {
    store
      .dispatch('invoice/exportInvoicesAsZip', serverParams.value)
      .then(() => {
        // window.location.href = response.data;
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportZipPdf = () => {
    store
      .dispatch('invoice/exportInvoicesAsZipPdf', serverParams.value)
      .then(() => {
        // window.location.href = response.data;
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  const setPaymentHidden = invoices => {
    const contractIds = invoices.map(obj => obj.contractId);

    const distinct = new Set(contractIds).size;
    if (distinct === 1) {
      // kiem tra xem tat ca da duoc duyet hay chua
      const countApprove = invoices.filter(obj => obj.approve).length;
      const countRemain = invoices.filter(obj => obj.remain !== 0).length;
      if (countApprove === invoices.length && countRemain === invoices.length) {
        return false;
      }
    }
    return true;
  };

  const setDeleteEnable = invoices => {
    // Chi khi tat ca hoa don chua duyet va chua thanh toan moi xoa duoc, con lai la khong hien thi nut xoa
    if (invoices.length === 0) {
      return false;
    }

    if (invoices.filter(obj => obj.approve === true).length > 0) {
      return false;
    }
    return true;
  };

  const onCreateMultiple = () => {

  };

  const setApproveHidden = invoices => {
    // Tat ca hoa don dang o trang thai chua duyet thi se hien thi nut approve

    const numOfApprove = invoices.filter(obj => obj.approve === false).length;
    return !(invoices.length > 0 && numOfApprove === invoices.length);
  };

  const setDeclineHidden = invoices => {
    // Hiển thị khi tất cả hóa đơn ở trạng thái đã duyệt
    const numOfDecline = invoices.filter(obj => obj.approve === true).length;
    return !(invoices.length > 0 && numOfDecline === invoices.length);
  };

  const onSelectPayment = invoices => {
    const totalRemain = invoices.length === 1 ? invoices[0].totalDebt : invoices.reduce((partialSum, invoice) => partialSum + invoice.remain, 0);

    let name = '';
    const firstInvoice = invoices[0];
    if (firstInvoice.reservation && firstInvoice.reservation.tenant) {
      name = firstInvoice.reservation.tenant.name;
    } else if (firstInvoice.contract) {
      name = firstInvoice.contract.name;
    }

    paymentItem.value = {
      totalRemain,
      invoices,
      type: null,
      name: '',
      issueDate: moment(new Date()).toDate(),
      amount: Math.abs(totalRemain),
      cashbook: null,
      payer: totalRemain > 0 ? name : '',
      receiver: totalRemain > 0 ? '' : name,
      note: '',
    };
  };

  const approveInvoice = (invoices, approve) => {
    if (invoices && invoices.length > 0) {
      store.dispatch('invoice/approveInvoices', { approve, ids: invoices.map(_obj => _obj.id) }).then(() => {
        fetchInvoices();
      }).catch(error => {
        toastification.showToastError(error);
      });
    }
  };

  //   Watch
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(bed, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.bedId = val.id;
    } else {
      delete filter.bedId;
    }
    updateParams({ filter });
  });
  watch(contract, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.contractId = val.id;
    } else {
      delete filter.contractId;
    }
    updateParams({ filter });
  });
  watch(status, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.value;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.month = month.value;
    } else {
      delete filter.month;
    }
    updateParams({ filter });
  });
  watch(approveStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.approve = val.value;
    } else {
      delete filter.approve;
    }
    updateParams({ filter });
  });
  watch(paymentStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.id;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(dueDateStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.dueDateStatus = val.id;
    } else {
      delete filter.dueDateStatus;
    }
    updateParams({ filter });
  });
  watch(mixedType, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.mixedType = val.value;
    } else {
      delete filter.mixedType;
    }
    updateParams({ filter });
  });
  watch(invoiceType, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.invoiceType = val.value;
    } else {
      delete filter.invoiceType;
    }
    updateParams({ filter });
  });

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceApproveStatusVariantAndIcon = approve => {
    if (approve) return { variant: 'success', title: t('Đã duyệt') };
    return { variant: 'danger', title: t('Chưa duyệt') };
  };

  const getRemainDaysVariant = remainDays => {
    if (remainDays > 30) {
      return { variant: "light-primary", text: `Còn ${Math.abs(remainDays)} ngày` };
    }
    if (remainDays > 0) {
      return { variant: "light-warning", text: `Còn ${Math.abs(remainDays)} ngày` };
    }
    if (remainDays === 0) {
      return { variant: "light-warning", text: t(`Quá hạn hôm nay`) };
    }
    return { variant: "light-danger", text: `Quá hạn ${Math.abs(remainDays)} ngày` };
  };

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 4;
  };

  return {
    analytics,
    item,
    paymentItem,
    columns,
    rows,
    location,
    apartment,
    room,
    bed,
    contract,
    month,
    status,
    paymentStatus,
    type,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    approveStatus,
    dueDateStatus,
    mixedType,
    invoiceType,
    exportZipPdf,
    importData,
    exportData,
    downloadImportTemplate,
    setPaymentHidden,
    approveInvoice,
    fetchData,
    deleteInvoices,
    onEditItem,
    onSelectPayment,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    resolveInvoiceApproveStatusVariantAndIcon,
    getRemainDaysVariant,
    setApproveHidden,
    setDeclineHidden,
    setDeleteEnable,
    onCreateMultiple,
    resolveColWidthIfDisableBed,
    exportZipImages,
    t,
    isEnableLocationModule,
    isTingTong,
  };
}
